import React, { useState, useEffect } from "react";
import "./Aplications.scss";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { withRouter } from "react-router-dom";
import BigTitle from "../BigTitle";
import * as dataJSON from "../../Data/Applications.json";

window.currentCircleRotation = 0;
let path = window.currentCircleRotation;
window.addEventListener("DOMContentLoaded", () => {
    path = 0;
});

const Aplications = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default[lang]);
    const [deg, setDeg] = useState({
        top: "14%",
        right: "14%",
        bottom: "auto",
        left: "auto",
    });

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);

    const circle = {
        transform: deg.transform,
    };
   
    const changeImage= (deg)=>{
        if (deg===315){
           // setSrcImage('https://mad-up.com/api/uploads/mad-up/originals/appPreop.png');
             document.getElementById("img").src='https://mad-up.com/api/uploads/mad-up/originals/appPreop.png'
          
        }
        if( deg===275){
           // setSrcImage('https://mad-up.com/api/uploads/mad-up/originals/applicationPostop.png')
            document.getElementById("img").src='https://mad-up.com/api/uploads/mad-up/originals/applicationPostop.png'
           
        }
        if( deg===225){
           // setSrcImage("https://mad-up.com/api/uploads/mad-up/originals/applicationRehab.png");
            document.getElementById("img").src='https://mad-up.com/api/uploads/mad-up/originals/applicationRehab.png'
           
        }
        if( deg===135){
            //setSrcImage("https://mad-up.com/api/uploads/mad-up/originals/applicationSénior.png");
            document.getElementById("img").src='https://mad-up.com/api/uploads/mad-up/originals/applicationSénior.png'
           
        }
        if( deg===180){
            //setSrcImage("https://mad-up.com/api/uploads/mad-up/originals/applicationPerformance.png");
            document.getElementById("img").src='https://mad-up.com/api/uploads/mad-up/originals/applicationPerformance.png'
           
        }
      

    }
    const setEffects = (deg) => {
        const currentRelativeSpin = window.currentCircleRotation % 360;
        let curentDeg = deg;
        let distanceRight = curentDeg - currentRelativeSpin;
        const distanceLeft = (360 - distanceRight) % 360;

        if (deg === 315 && path === 315) {
            path -= distanceLeft;
        }

        if (currentRelativeSpin > 180 && curentDeg === 0) {
            curentDeg = 360;
        }

        if (distanceRight > distanceLeft) {
            path -= distanceLeft;
        } else {
            path += distanceRight;
        }

        window.currentCircleRotation = path;

        setDeg({
            transform: `rotate(${window.currentCircleRotation}deg)`,
        });
    };

    return (
        <>
            <section className="aplications">
                <BigTitle
                    text={data.bigtitle.text}
                    title={data.bigtitle.title}
                    direct="left"
                />
                <div className="container">
                    <div className="aplications_left">
                        <HashLink
                           to={`/${lang}${data.item[0].url}`}
                            // onClick={() => window.scrollTo(0, 0)}
                        >
                            <div
                                className="aplications_item"
                                onMouseEnter={() => {
                                    setEffects(315);
                                    changeImage(315)
                                }}
                            >
                                <div className="aplications_icon">
                                    <i className={data.item[0].icon}></i>
                                </div>
                                <div className="aplications_title">
                                    {data.item[0].title}
                                </div>
                                <div className="aplications_text">
                                    {data.item[0].text}
                                </div>
                                <div className="aplications_link">
                                    {data.button_text}
                                    <span className="aplications_link_arrow">
                                        &rarr;
                                    </span>
                                </div>
                            </div>
                        </HashLink>
                        <HashLink
                           to={`/${lang}${data.item[1].url}`}
                            // onClick={() => window.scrollTo(0, 0)}
                        >
                            <div
                                className="aplications_item"
                                onMouseEnter={() => {
                                    setEffects(275);
                                    changeImage(275)
                                }}
                            >
                                <div className="aplications_icon">
                                    <i className={data.item[1].icon}></i>
                                </div>
                                <div className="aplications_title">
                                    {data.item[1].title}
                                </div>
                                <div className="aplications_text">
                                    {data.item[1].text}
                                </div>
                                <div className="aplications_link">
                                    {data.button_text}
                                    <span className="aplications_link_arrow">
                                        &rarr;
                                    </span>
                                </div>
                            </div>
                        </HashLink>
                    </div>
                    <div className="aplications_center">
                        <div id="round">
                            <div id="circle" style={circle}>
                                <div id="subcircle"></div>
                            </div>
                            {/* <div className="aplications_center_text">
                                {/* {data.roundtext} */}
                              
                            {/* </div> */} 
                            {lang === "fr" && <img src="https://mad-up.com/api/uploads/mad-up/originals/defaultFR.png"  alt="" className="fonctionnement_center_image" id="img"/>}
                            {lang === "en" && <img src="https://mad-up.com/api/uploads/mad-up/originals/parDefautENG.png" alt="" className="fonctionnement_center_image" id="img"/>}
                            
                        </div>
                        <HashLink
                           to={`/${lang}${data.item[2].url}`}
                            // onClick={() => window.scrollTo(0, 0)}
                        >
                            <div
                                className="aplications_item"
                                onMouseEnter={() => {
                                    setEffects(225);
                                    changeImage(225);
                                }}
                            >
                                <div className="aplications_icon">
                                    <i className={data.item[2].icon}></i>
                                </div>
                                <div className="aplications_title">
                                    {data.item[2].title}
                                </div>
                                <div className="aplications_text">
                                    {data.item[2].text}
                                </div>
                                <div className="aplications_link">
                                    {data.button_text}
                                    <span className="aplications_link_arrow">
                                        &rarr;
                                    </span>
                                </div>
                            </div>
                        </HashLink>
                    </div>

                    <div className="aplications_right">
                        <Link
                           to={`/${lang}${data.item[3].url}`}
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            <div
                                className="aplications_item"
                                onMouseEnter={() => {
                                    setEffects(135);
                                    changeImage(135)
                                }}
                            >
                                <div className="aplications_icon">
                                    <i className={data.item[3].icon}></i>
                                </div>
                                <div className="aplications_title">
                                    {data.item[3].title}
                                </div>
                                <div className="aplications_text">
                                    {data.item[3].text}
                                </div>
                                <div className="aplications_link">
                                    {data.button_text}
                                    <span className="aplications_link_arrow">
                                        &rarr;
                                    </span>
                                </div>
                            </div>
                        </Link>

                        <Link
                           to={`/${lang}${data.item[4].url}`}
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            <div
                                className="aplications_item"
                                onMouseEnter={() => {
                                    setEffects(180);
                                    changeImage(180)
                                }}
                            >
                                <div className="aplications_icon">
                                    <i className={data.item[4].icon}></i>
                                </div>
                                <div className="aplications_title">
                                    {data.item[4].title}
                                </div>
                                <div className="aplications_text">
                                    {data.item[4].text}
                                </div>
                                <div className="aplications_link">
                                    {data.button_text}
                                    <span className="aplications_link_arrow">
                                        &rarr;
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
};
export default withRouter(Aplications);
