import React, { createRef } from "react";
import BigTitle from "../../Components/BigTitle";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/DemoRequest.json";
import "./DemoRequest.scss";
import Meta from "../../Components/Meta";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import Recaptcha from "react-recaptcha";
class DemoRequest extends React.Component {
  state = {
    lang: this.props.match.params.lang,
    messageReponse: false,
    messageError: false,

    meta: {
      title: dataJSON.default[this.props.match.params.lang].meta.title,
      description:
        dataJSON.default[this.props.match.params.lang].meta.description,
      canonical: dataJSON.default[this.props.match.params.lang].meta.canonical,
      image: dataJSON.default[this.props.match.params.lang].meta.image,
      keywords: dataJSON.default[this.props.match.params.lang].meta.keywords,
    },
    bigtitle: {},
    label: {},
    validate: {},
    country:[],
    regionFr:[],
    countryFR:[],
    countryEn:[],
    commune:[],
    subject:[]    
  };

//récuperer les pays
 
   
  
  componentDidMount() {
    this.setState({
      lang: this.props.match.params.lang,
      data: dataJSON.default[this.state.lang],
      meta: dataJSON.default[this.state.lang].meta,
      bigtitle: dataJSON.default[this.state.lang].bigtitle,
      label: dataJSON.default[this.state.lang].label,
      validate: dataJSON.default[this.state.lang].validate,
      commune:dataJSON.default[this.state.lang].commune,
      subject:dataJSON.default[this.state.lang].subject
    });
    //recaptcha
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcRCgYhAAAAAE959V_cpYVPav7dscBaucxP8jc_";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    
    fetch('https://restcountries.com/v3.1/all')
    .then(response => response.json()
   )
    .then(data => 
      {
      this.setState({ country:data})
     
      // console.log(this.state.countryFR)
    
    });

      fetch('https://geo.api.gouv.fr/regions')
      .then(response => response.json()
     )
      .then(dataR => 
        {
        this.setState({ regionFr:dataR})});
  

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.lang !== this.props.match.params.lang) {
      this.setState({
        lang: this.props.match.params.lang,
        data: dataJSON.default[this.state.lang],
        meta: dataJSON.default[this.state.lang].meta,
        bigtitle: dataJSON.default[this.state.lang].bigtitle,
        label: dataJSON.default[this.state.lang].label,
        validate: dataJSON.default[this.state.lang].validate,
        commune:dataJSON.default[this.state.lang].commune,
        subject:dataJSON.default[this.state.lang].subject
      });
    }
  }
//envoi de formuliare
  handleSubmit = (message) => {
    fetch("https://mad-up.com/api/mad-up/items/demo_requests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstname: message.firstname,
        lastname: message.lastname,
        email: message.email,
        phone: message.email,
        company_name: message.company_name,
        country: message.country,
        region: message.region,
        subject: message.subject,
      }),
    })
      .then((response) => {
       // console.log(response);
        if (response.status == 200) {
          this.setState({ messageReponse: true });
          this.setState({ messageError: false });
         // console.log("state:", this.state.messageReponse);
        } else {
          this.setState({ messageError: true });

          this.setState({ messageReponse: false });
          //console.log("stateError:", this.state.messageError);
        }
      })
      .catch((error) => {
        this.setState({ messageError: true });
        // console.log("stateError:", this.state.messageError);
        // console.log("Error: ", error);
      });


      
  };
 


  render() {
    const messageMail = this.state.messageReponse;
    const messageError = this.state.messageError;
   

    if (messageMail) {
      document.querySelector(".message").innerHTML =
        this.state.data.validate.reponse;
      document.querySelector(".message").classList.add("reponseTrue");
    }

    if (messageError) {
      document.querySelector(".message").innerHTML =
        this.state.data.validate.error;
      document.querySelector(".message").classList.add("reponseError");
    }

    const InputEmail = Yup.object().shape({
      email: Yup.string()
        .email(this.state.validate.errorMail)
        .required(this.state.validate.errorForm),
      firstname: Yup.string().required(this.state.validate.errorForm),
      lastname: Yup.string().required(this.state.validate.errorForm),
      phone: Yup.string().required(this.state.validate.errorForm),
      recaptcha: Yup.string().required(this.state.validate.errorForm),
      region: Yup.string().required(this.state.validate.errorForm),
      country: Yup.string().required(this.state.validate.errorForm),
      subject: Yup.string().required(this.state.validate.errorForm),
      company_name: Yup.string().required(this.state.validate.errorForm),
    });

    this.state.country.sort(function compare(a, b) {
      if (a.translations.fra.common < b.translations.fra.common)
         return -1;
      if (a.translations.fra.common > b.translations.fra.common )
         return 1;
      return 0;
    });
    
    this.state.country.sort(function compare(a, b) {
      if (a.name.common < b.name.common)
         return -1;
      if (a.name.common > b.name.common )
         return 1;
      return 0;
    });
    this.state.regionFr.sort(function compare(a, b) {
      if (a.nom < b.nom)
         return -1;
      if (a.nom > b.nom )
         return 1;
      return 0;
    });
    return (
      <>
        <Meta meta={this.state.meta} />
        <BigTitle
          text=""
          title={this.state.bigtitle.title}
          span={this.state.bigtitle.span}
          direct="left"
        />

        <div className="form ">
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              phone: "",
              company_name: "",
              country: "",
              region: "",
              subject: "",
              recaptcha: "",
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
             // console.log(values)
            }}
            validationSchema={InputEmail}
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleBlur,
              handleChange,
              isSubmitting,
            }) => (
              <form className="checkboxMail" onSubmit={handleSubmit} >
                <div className="formLabel">
                  <div className="form-group mb-3">
                    <label htmlFor="name">{this.state.label.firstName}</label>
                    <Field
                      type="text"
                      id="firstname"
                      name="firstname"
                      className="form-control input"
                    />
                    <ErrorMessage
                      name="firstname"
                      component="small"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="lastname">
                      {this.state.label.lastName}
                    </label>
                    <Field
                      type="text"
                      id="lastname"
                      name="lastname"
                      className="form-control input"
                    />
                    <ErrorMessage
                      name="lastname"
                      component="small"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="company_name">{this.state.label.company_name}</label>
                  <Field
                    type="text"
                    id="company_name"
                    name="company_name"
                    className="form-control input"
                  />
                  <ErrorMessage
                    name="company_name"
                    component="small"
                    className="text-danger"
                  />
                </div>
                <div className="formLabel">
                  <div className="form-group  mb-3">
                    <label htmlFor="email">{this.state.label.email}</label>

                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="form-control input"
                    />
                    <ErrorMessage
                      name="email"
                      component="small"
                      className="text-danger"
                    />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="phone">{this.state.label.phone}</label>
                      <Field
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control input"
                      />
                      <ErrorMessage
                        name="phone"
                        component="small"
                        className="text-danger"
                      />
                   
                  </div>
                </div>
              
                <div className="form-group mb-3">
                      <label htmlFor="country">{this.state.label.country}</label>
                      <Field as="select" name="country" className="form-control input">
                      <option disabled  value="">{this.state.label.placeholderCountry}</option>
                      {this.state.country.map((country,index) => {
                       return (this.state.lang ==="fr") ?
                          <option key={index} value={country.translations.fra.common}>
                          {country.translations.fra.common}
                        </option>
                        :
                        <option key={country.name.common} value={country.name.common}>
                          {country.name.common}
                        </option>
                  })}
                    </Field>
                      <ErrorMessage
                        name="country"
                        component="small"
                        className="text-danger"
                      />
                     
                   
                  </div>
                  {values.country==="France" && 
                     <div className="form-group mb-3">
                     <label htmlFor="region">{this.state.label.region}</label>
                       
                       <Field as="select" name="region" className="form-control input" >
                       <option disabled  value="">{this.state.label.placeholderRegion}</option>
                       {this.state.regionFr.map(({nom,index}) => (
                       <option key={nom} value={nom}>
                         {nom} 
                       </option>
                       ))}
                     </Field>
                       <ErrorMessage
                         name="region"
                         component="small"
                         className="text-danger"
                       />
                   </div>
                  }

                    {((values.country==="Spain")|| (values.country==="Espagne")) && 
                     <div className="form-group mb-3">
                     <label htmlFor="region">{this.state.label.region}</label>
                       
                       <Field as="select" name="region" className="form-control input"  >
                       <option disabled  value="">{this.state.label.placeholderRegion}</option>
                       {this.state.commune.sort().map((nom) => (
                       <option key={nom} value={nom}>
                         {nom}
                       </option>
                       ))}
                     </Field>
                       <ErrorMessage
                         name="region"
                         component="small"
                         className="text-danger"
                       />
                       
                      
                 
                   </div> 
                   }
                  
                  <div className="form-group mb-3">
                      <label htmlFor="subject">{this.state.label.subject}</label>
                      <Field as="select" name="subject" className="form-control input">
                      <option disabled  value="">{this.state.label.placeholderSubject}</option>
                      {this.state.subject.sort().map((subject,index) => (
                       
                          <option key={index} value={subject}>
                          {subject}
                        </option>
                       
                           ))}
                    </Field>
                      <ErrorMessage
                        name="subject"
                        component="small"
                        className="text-danger"
                      />
                     
                   
                  </div>
               
                 
                <div className="form-group">
                  <Recaptcha
                    sitekey="6LcRCgYhAAAAAFZQ0kUrOCx-HgCMVxhlWNZ2gZMr"
                    render="explicit"
                    verifyCallback={(response) => {
                      console.log(response);
                      setFieldValue("recaptcha", response);
                    }}
                    onloadCallback={() => {
                      console.log("done loading!");
                    }}
                  />
                  {errors.recaptcha && touched.recaptcha && (
                    <p className="text-danger small">{errors.recaptcha}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className="colorButton"
                  disabled={isSubmitting}
                >
                  {this.state.validate.textSubmit}
                </button>
              </form>
            )}
          />

          <p className="message"></p>
        </div>
      </>
    );
  }
}

export default withRouter(DemoRequest);
